<template>
  <div class="w-100 section__container">
    <h2 :class="[{'section__title--invert': invert}, 'section__title']">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'Header',
  props: {
    title: {
      type: String,
      required: true,
    },
    invert: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.section {
  &__container {
    text-align: center;
  }

  &__title {
    position: relative;
    display: inline-block;

    @media #{$sm-max} {
      display: block;
    }

    &::before {
      content: '';
      background-color: $secondary-color;
      background-size: cover;
      width: 4rem;
      height: 2px;
      position: absolute;
      bottom: -.5rem;
      left: -1rem;
    }

    &--invert {
      color: $text-invert-color;

      &::before {
        background-color: $text-invert-color;
      }
    }
  }
}
</style>
