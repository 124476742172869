import { createRouter, createWebHistory } from 'vue-router';
import Home from '../views/Home';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/o-mnie',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About'),
  },
  {
    path: '/projekty',
    name: 'Projects',
    component: () => import(/* webpackChunkName: "projects" */ '../views/Projects'),
  },
  {
    path: '/kontakt',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/Contact'),
  },
  {
    path: '/polityka-prywatnosci',
    name: 'Policy',
    component: () => import(/* webpackChunkName: "policy" */ '../views/PrivacyPolicy'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' };
  },
});

export default router;
