import { createStore } from 'vuex';
import axios from 'axios';

axios.defaults.baseURL = `${process.env.VUE_APP_API_URL}/wp-json/ak/v1/`;
const apiKey = process.env.VUE_APP_API_KEY;

export default createStore({
  state: {
    projects: [],
    offers: [],
    technologies: [],
  },
  getters: {
    projects: (state) => state.projects,
    offers: (state) => state.offers,
    technologies: (state) => state.technologies,
  },
  mutations: {
  },
  actions: {
    async getProjects({ state }) {
      try {
        const { data } = await axios.get(`project?apiKey=${apiKey}`);
        state.projects = data;
      } catch (error) {
        console.log(error);
      }
      return state.projects;
    },
    async getProject(payload, id) {
      let project = null;
      try {
        const { data } = await axios.get(`project/${id}?apiKey=${apiKey}`);
        project = data;
      } catch (error) {
        console.log(error);
      }
      return project;
    },
    async getOffers({ state }) {
      try {
        const { data } = await axios.get(`offers?apiKey=${apiKey}`);
        state.offers = data;
      } catch (error) {
        console.log(error);
      }
      return state.offers;
    },
    async getOffer(payload, id) {
      let offer = null;
      try {
        const { data } = await axios.get(`offers/${id}?apiKey=${apiKey}`);
        offer = data;
      } catch (error) {
        console.log(error);
      }
      return offer;
    },
    async getTechnologies({ state }) {
      try {
        const { data } = await axios.get(`technologies?apiKey=${apiKey}`);
        state.technologies = data;
      } catch (error) {
        console.log(error);
      }
      return state.technologies;
    },
    async getPageContent(payload, id) {
      let content = null;
      try {
        const { data } = await axios.get(`page/${id}?apiKey=${apiKey}`);
        content = data;
      } catch (error) {
        console.log(error);
      }
      return content;
    },
  },
  modules: {
  },
});
