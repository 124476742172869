<template>
  <h2 class="page__title">{{ title }}</h2>
</template>

<script>
export default {
  name: 'PageTitle',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.page {
  &__title {
    color: $text-invert-color;
    border: 3px solid $primary-color;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    word-spacing: 4px;
    min-width: 100%;
    padding: 3rem 1rem;
    margin-top: 0;

    @media #{$md-min} {
      min-width: 40%;
    }
  }
}
</style>
