<template>
  <div class="home">
    <div class="container flex justify-center content-center direction-column h-100">
      <PageTitle :title="'Witaj! Jestem Adam'"/>
      <p>Jestem Web Developerem.</p>
      <p>Pomogę Ci stworzyć Twoją wymarzoną stronę lub aplikację.</p>
      <button type="button" class="icon__circle-with-arrow" @click="scrollDown"></button>
    </div>
  </div>
  <div class="section" id="scroll">
    <div class="container">
      <Header :title="'Co potrzebujesz stworzyć?'"/>
    </div>
    <div class="container flex justify-center vertical-center direction-column--md">
      <div class="hexagon__container" v-for="project in offers" :key="project">
        <div class="hexagon">
          <h3 class="hexagon__title">{{ project.title }}</h3>
          <div class="btn__container">
            <button type="button"
                    class="btn btn--primary"
                    @click="openDetails(project)">
              Zobacz szczegóły
            </button>
          </div>
          <img v-if="project.icon" :src="project.icon" class="hexagon__img" />
        </div>
      </div>
      <div class="hexagon__container">
        <div class="hexagon hexagon--empty"></div>
      </div>
    </div>
  </div>
  <transition name="fade">
    <ProjectDetails
      :title="openProject.title"
      :description="openProject.description"
      :img="openProject.img"
      :cost="openProject.cost"
      :work-time="openProject.workTime"
      :difficulty="openProject.difficulty"
      v-if="isOpen"
      v-on:clickBtn="closeDetails"/>
  </transition>
  <div class="section bg--invert bg--logo">
    <div class="container flex justify-center">
      <Header :title="'Responsive Web Design'" :invert="true"/>
      <div class="w-100 flex justify-center">
        <div class="w-thin">
          <p class="text--center text--invert">
            Przy pomocy techniki Responsive Web Design
            Twoja strona będzie dobrze wyświetlać się na każdym urządzeniu.
          </p>
        </div>
      </div>
      <div class="w-33">
        <img src="../assets/desktop.svg" alt="Ikona laptopa" class="item__img">
        <h3 class="item__title">Desktop</h3>
      </div>
      <div class="w-33">
        <img src="../assets/tablet.svg" alt="Ikona tableta" class="item__img">
        <h3 class="item__title">Tablet</h3>
      </div>
      <div class="w-33">
        <img src="../assets/mobile.svg" alt="Ikona telefonu" class="item__img">
        <h3 class="item__title">Mobile</h3>
      </div>
    </div>
  </div>
  <div class="section">
    <div class="container flex justify-center">
      <Header :title="'Korzyści'"/>
      <div class="w-33 text--center">
        <img :src="require('@/assets/conversation.svg')" alt="Terminowość">
        <h3 class="mt-1">Indywidualne podejście</h3>
      </div>
      <div class="w-33 text--center">
        <img :src="require('@/assets/calendar.svg')" alt="Terminowość">
        <h3 class="mt-1">Terminowość</h3>
      </div>
      <div class="w-33 text--center">
        <img :src="require('@/assets/details.svg')" alt="Terminowość">
        <h3 class="mt-1">Dbałość o szczegóły</h3>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap';
import { ScrollToPlugin } from 'gsap/all';
import { onMounted, ref } from 'vue';
import store from '../store';
import Header from '../components/Header';
import ProjectDetails from '../components/ProjectDetails';
import PageTitle from '../components/PageTitle';

gsap.registerPlugin(ScrollToPlugin);

export default {
  name: 'Home',
  components: { Header, ProjectDetails, PageTitle },
  setup() {
    const offers = ref([]);
    const isOpen = ref(false);
    const openProject = ref({
      title: '',
      description: '',
      img: '',
      cost: '',
      workTime: '',
      difficulty: '',
    });
    const scrollDown = () => {
      gsap.to(window, { duration: 1, scrollTo: '#scroll', ease: 'circ' });
    };
    const openDetails = async (item) => {
      const offer = await store.dispatch('getOffer', item.id);
      isOpen.value = !isOpen.value;
      openProject.value.title = offer.title;
      openProject.value.description = offer.description;
      openProject.value.img = offer.img;
      openProject.value.cost = offer.budget;
      openProject.value.workTime = offer.workTime;
      openProject.value.difficulty = offer.difficulty;
      document.body.classList.add('overflow-hidden');
    };

    const closeDetails = () => {
      isOpen.value = !isOpen.value;
      document.body.classList.remove('overflow-hidden');
    };

    onMounted(async () => {
      document.title = 'Strona główna - adamkanigowski.pl';
      await store.dispatch('getOffers');
      offers.value = store.getters.offers;
    });

    return {
      offers, isOpen, openProject, scrollDown, openDetails, closeDetails,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.home {
  background: url('../assets/hero.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
  position: relative;

  p {
    color: $text-invert-color;
    text-align: center;
    font-size: 1.25em;
    line-height: 1.25em;
  }
}

#scroll {
  min-height: 1000px;
}

.icon__circle-with-arrow  {
  border: 2px solid $background-color;
  border-radius: 100%;
  width: 3rem;
  height: 3rem;
  margin-left: -1.5rem;
  position: absolute;
  left: 50%;
  bottom: 4rem;
  animation: jump 4s 2s ease-in-out infinite;

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    border-left: 2px solid $background-color;
    border-bottom: 2px solid $background-color;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: .75rem;
    left: .85rem;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@keyframes jump {
  0% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  8% {
    -webkit-transform: translateY(-1rem);
    -moz-transform: translateY(-1rem);
    -ms-transform: translateY(-1rem);
    -o-transform: translateY(-1rem);
    transform: translateY(-1rem);
  }
  16% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
  24% {
    -webkit-transform: translateY(-1rem);
    -moz-transform: translateY(-1rem);
    -ms-transform: translateY(-1rem);
    -o-transform: translateY(-1rem);
    transform: translateY(-1rem);
  }
  32% {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}
</style>
