<template>
  <Navigation/>
  <main>
    <router-view/>
  </main>
  <Footer/>
</template>

<script>
import Navigation from './components/Navigation';
import Footer from './components/Footer';

export default {
  name: 'App',
  components: {
    Navigation,
    Footer,
  },
};
</script>

<style lang="scss">
@import 'scss/style';

.btn {
  color: $text-invert-color;
  background-color: $text-color;
  font-weight: bold;
  width: 10rem;
  padding: .5rem 1rem;
  margin: 0 auto;
  display: block;
  position: relative;
  transition: transform .4s ease-in-out;

  &:focus, :hover {
    outline: none;
    transform: translate(4px, 4px);

    &::before {
      transform: translate(0, 0);
    }
  }

  &::before {
    content: '';
    border: 2px solid $primary-color;
    width: 10rem;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(4px, 4px);
    transition: transform .4s ease-in-out;
  }

  &--primary {
    background-color: $primary-color;
  }
}

.bg {
  &--invert {
    background-color: $secondary-color;
  }

  &--logo {
    position: relative;

    &::before {
      content: '';
      background-image: url('assets/logo_invert.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30%;
      background-attachment: fixed;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0.02;
    }
  }
}

.badge {
  &__list {
    @include inline-flex;
    @include flex-wrap;
    margin-top: $margin-2;
  }

  &__element {
    margin: 0 $margin-2 $margin-3 0;
  }

  &__header {
    background-color: $primary-color;
    border: solid 2px $primary-color;
    padding: $padding-1 $padding-2;
    color: $text-invert-color;
    font-weight: bold;
  }

  &__value {
    background-color: $background-color;
    border: solid 2px $primary-color;
    padding: $padding-1 $padding-2;
    position: relative;
    border-top-right-radius: 1rem;
  }
}

.hexagon {
  position: relative;
  -webkit-clip-path: polygon(0% 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  clip-path: polygon(0% 25%, 50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%);
  background-color: $secondary-color;

  &__container {
    padding: 0.5rem;
    @media #{$sm-max} {
      &:nth-child(odd) {
        margin-left: -100px;
      }

      &:nth-child(even) {
        margin-right: -100px;
      }

      &:nth-child(2), &:nth-child(3), &:nth-child(4), &:nth-child(5) {
        margin-top: -60px;
      }
    }

    @media #{$md-min} and #{$md-max}  {
      &:nth-child(odd) {
        margin-left: -180px;
      }

      &:nth-child(even) {
        margin-right: -180px;
      }

      &:not(:first-child) {
        margin-top: -100px;
      }
    }

    @media #{$lg-min} {
      &:nth-child(4), &:nth-child(5) {
        margin-top: -100px;
      }
    }
  }

  &--empty {
    opacity: 0.2;

    @media #{$md-max} {
      display: none;
    }
  }

  @media #{$sm-max} {
    width: 200px;
    height: 220px;
  }

  @media #{$md-min} {
    width: 360px;
    height: 390px;
  }

  &__title {
    color: $text-invert-color;
    font-size: .9rem;
    line-height: .9rem;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    word-spacing: 3px;
    width: 100%;
    margin: -.45rem 0 0;
    padding: 0 1rem;
    position: absolute;
    top: 40%;
    left: 0;
    z-index: 2;

    @media #{$md-min} {
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin: -.6125rem 0 0;
      padding: 0 .5rem;
      top: 50%;
    }
  }

  .btn__container {
    width: 100%;
    position: absolute;
    top: 55%;
    left: 0;
    z-index: 2;

    @media #{$md-min} {
      top: 65%;
    }

    @media #{$sm-max} {
      .btn {
        padding: .25rem .5rem;
        font-size: .75rem;
      }
    }
  }

  &__img {
    max-width: 80%;
    min-width: 70%;
    max-height: 80%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.05;
    z-index: 1;
  }
}

svg {
  width: 100%;
  height: auto;
}

.item {
  &__img {
    max-width: 100%;
    height: 150px;
    display: block;
    margin: 0 auto;
  }

  &__title {
    color: $text-invert-color;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: $margin-2;
  }
}

/* Transition */
.fade-enter-from {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}

.fade-enter-active {
  -webkit-transition: opacity .4s .2s ease-in-out;
  -moz-transition: opacity .4s .2s ease-in-out;
  -o-transition: opacity .4s .2s ease-in-out;
  transition: opacity .4s .2s ease-in-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  -webkit-transition: opacity .2s ease-out;
  -moz-transition: opacity .2s ease-out;
  -o-transition: opacity .2s ease-out;
  transition: opacity .2s ease-out;
}
</style>
