<template>
  <div class="project__container">
    <div class="container">
      <div class="project__wrapper">
        <Header :title="title"/>
        <div class="flex content-start project__content">
          <div class="w-50">
            <img :src="img" alt="Zdjęcie prezentujące projekt">
          </div>
          <div class="w-50">
            <ul class="badge__list" v-if="cost || workTime || difficulty">
              <li class="badge__element" v-if="cost">
                <span class="badge__header">Budżet</span>
                <span class="badge__value">{{ cost }}</span>
              </li>
              <li class="badge__element" v-if="workTime">
                <span class="badge__header">Czas pracy</span>
                <span class="badge__value">{{ workTime }}</span>
              </li>
              <li class="badge__element" v-if="difficulty">
                <span class="badge__header">Stopień trudności</span>
                <span class="badge__value">{{ difficulty }}</span>
              </li>
            </ul>
            <div v-html="description" class="project__description"></div>
          </div>
        </div>
        <button class="btn--close"
                type="button"
                @click="$emit('clickBtn')">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Header from './Header';

export default {
  name: 'ProjectDetails',
  components: { Header },
  props: {
    title: String,
    description: String,
    img: String,
    cost: String,
    workTime: String,
    difficulty: String,
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars', '../scss/mixins';
.project {
  &__container {
    width: 100%;
    height: 100vh;
    @include flex;
    @include vertical-center;
    z-index: 10;
    position: fixed;
    top: 0;
    left: 0;

    &::before {
      content: '';
      background-color: rgba(0,0,0,.75);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
    }
  }

  &__wrapper {
    background-color: $background-color;
    -webkit-border-radius: .25rem;
    -moz-border-radius: .25rem;
    border-radius: .25rem;
    width: 100%;
    height: 80vh;
    padding: $padding-3;
    position: relative;
    overflow: hidden;

    @media #{$sm-max} {
      border-radius: 0;
      width: 100vw;
      height: 100vh;
      margin-left: -$margin-2;
    }

    &::before {
      content: '';
      background-image: linear-gradient(to top right, $background-color 50%, $primary-color 50%);
      width: 6rem;
      height: 6rem;
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__content {
    height: calc(80vh - 11rem);
    overflow-y: auto;

    @media #{$sm-max} {
      height: calc(100vh - 11rem);
      width: calc(100% + 1rem);
      padding-right: $padding-2;
    }

    @media #{$lg-min} {
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: #f0f0f0;
      }

      &::-webkit-scrollbar {
        width: .5rem;
        background-color: $secondary-color;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        background-color: $secondary-color;
      }
    }
  }
}

.btn {
  &--close {
    width: 2rem;
    height: 2rem;
    position: absolute;
    top: 1rem;
    right: 1rem;

    &::before, &::after {
      content: '';
      background-color: $text-invert-color;
      border-radius: 100%;
      width: 2rem;
      height: 2px;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-transform: translateY(1rem) rotate(45deg);
      -moz-transform: translateY(1rem) rotate(45deg);
      -ms-transform: translateY(1rem) rotate(45deg);
      -o-transform: translateY(1rem) rotate(45deg);
      transform: translateY(1rem) rotate(45deg);
    }

    &::after {
      -webkit-transform: translateY(1rem) rotate(-45deg);
      -moz-transform: translateY(1rem) rotate(-45deg);
      -ms-transform: translateY(1rem) rotate(-45deg);
      -o-transform: translateY(1rem) rotate(-45deg);
      transform: translateY(1rem) rotate(-45deg);
    }
  }
}
</style>
