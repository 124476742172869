<template>
  <header>
    <nav class="nav">
      <div class="container flex space-between">
        <router-link to="/">
          <div class="nav__brand flex vertical-center">
            <img src="../assets/logo_invert.svg" alt="Logo" class="brand__img">
            <h1 class="brand__text">Adam Kanigowski</h1>
          </div>
        </router-link>
        <transition name="list">
          <div v-if="navOpen"
              class="nav__list flex justify-center vertical-center direction-column">
            <transition-group
                tag="ul"
                appear
                @beforeEnter="beforeEnter"
                @enter="enter"
            >
              <li v-for="(link, index) of links" :key="link" :data-index="index">
                <router-link :to="{ name: link.name }" @click="closeNavigation" class="nav__link">
                  {{ link.link }}
                </router-link>
              </li>
            </transition-group>
          </div>
        </transition>
        <button :class="[{'hamburger--active': navOpen}, 'hamburger']"
                @click="closeNavigation">
          <span class="hamburger__line"></span>
        </button>
      </div>
    </nav>
  </header>
</template>

<script>
import { ref } from 'vue';
import gsap from 'gsap';

export default {
  name: 'Navigation',
  setup() {
    const navOpen = ref(false);
    const links = [
      {
        link: 'Home',
        name: 'Home',
      },
      {
        link: 'Projekty',
        name: 'Projects',
      },
      {
        link: 'O mnie',
        name: 'About',
      },
      {
        link: 'Kontakt',
        name: 'Contact',
      },
    ];
    const beforeEnter = (el) => {
      const a = el;
      a.style.transform = 'translateX(100%)';
      a.style.opacity = '0';
      document.body.classList.add('overflow-hidden');
    };
    const enter = (el, done) => {
      gsap.to(el, {
        duration: 1,
        x: 0,
        opacity: 1,
        onComplete: done,
        delay: el.dataset.index * 0.12,
        ease: 'circ',
      });
    };
    const closeNavigation = () => {
      navOpen.value = !navOpen.value;
      document.body.classList.remove('overflow-hidden');
    };

    return {
      navOpen, links, beforeEnter, enter, closeNavigation,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.nav {
  width: 100%;
  padding: .5rem 0 .5rem;
  position: absolute;
  z-index: 10;

  @media #{$md-min} {
    padding: 1rem 0 1rem;
  }

  a {
    text-decoration: none;

    &.router-link-exact-active {
      color: $primary-color;
    }
  }

  &__list {
    background-color: $secondary-color-transparent;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;

    ul {
      width: 100%;
    }

    li {
      width: 100%;
      text-align: center;
    }
  }

  &__link {
    color: $text-invert-color;
    font-size: 1.75rem;
    line-height: 2rem;
    padding: 1rem 0;
    width: 95%;
    display: block;
    margin: 0 auto;
    -webkit-transition: transform .3s;
    -moz-transition: transform .3s;
    -o-transition: transform .3s;
    transition: transform .3s;

    @media #{$md-min} {
      width: 50%;
    }

    &:hover, &:focus {
      -webkit-transform: translateY(-.5rem);
      -moz-transform: translateY(-.5rem);
      -ms-transform: translateY(-.5rem);
      -o-transform: translateY(-.5rem);
      transform: translateY(-.5rem);
    }
  }
}

.brand {
  &__img {
    height: 2.5rem;

    @media #{$md-min} {
      height: 4rem;
    }
  }

  &__text {
    color: $text-invert-color;
    font-size: 2.5rem;
    line-height: 2.5rem;
    letter-spacing: 2px;
    margin-left: $margin-2;

    @media #{$sm-max} {
      display: none;
    }
  }
}

.hamburger {
  width: 4rem;
  height: 2.5rem;
  padding: 1rem;
  margin-right: -1rem;

  @media #{$md-min} {
    width: 5rem;
    height: 4rem;
  }

  &__line {
    background-color: $background-color;
    width: 2rem;
    height: 1px;
    display: block;
    position: relative;
    -webkit-transition: background-color .3s .2s ease-in-out;
    -moz-transition: background-color .3s .2s ease-in-out;
    -o-transition: background-color .3s .2s ease-in-out;
    transition: background-color .3s .2s ease-in-out;

    @media #{$md-min} {
      width: 3rem;
      height: 1px;
    }

    &::before, &::after {
      content: '';
      background-color: $background-color;
      width: 2rem;
      height: 1px;
      position: absolute;
      left: 0;
      -webkit-transition: transform .4s ease-in-out;
      -moz-transition: transform .4s ease-in-out;
      -o-transition: transform .4s ease-in-out;
      transition: transform .4s ease-in-out;

      @media #{$md-min} {
        width: 3rem;
        height: 1px;
      }
    }

    &::before {
      top: -.5rem;

      @media #{$md-min} {
        top: -.75rem;
      }
    }

    &::after {
      bottom: -.5rem;

      @media #{$md-min} {
        bottom: -.75rem;
      }
    }
  }

  &--active {
    .hamburger__line {
      background-color: transparent;
      -webkit-transition-delay: 0s;
      -moz-transition-delay: 0s;
      -o-transition-delay: 0s;
      transition-delay: 0s;

      &::before {
        -webkit-transform: translateY(-.75rem) rotate(45deg);
        -moz-transform: translateY(-.75rem) rotate(45deg);
        -ms-transform: translateY(-.75rem) rotate(45deg);
        -o-transform: translateY(-.75rem) rotate(45deg);
        transform: translateY(.5rem) rotate(45deg);

        @media #{$md-min} {
          -webkit-transform: translateY(-.75rem) rotate(45deg);
          -moz-transform: translateY(-.75rem) rotate(45deg);
          -ms-transform: translateY(-.75rem) rotate(45deg);
          -o-transform: translateY(-.75rem) rotate(45deg);
          transform: translateY(.75rem) rotate(45deg);
        }
      }

      &::after {
        -webkit-transform: translateY(-.75rem) rotate(-45deg);
        -moz-transform: translateY(-.75rem) rotate(-45deg);
        -ms-transform: translateY(-.75rem) rotate(-45deg);
        -o-transform: translateY(-.75rem) rotate(-45deg);
        transform: translateY(-.5rem) rotate(-45deg);

        @media #{$md-min} {
          -webkit-transform: translateY(-.75rem) rotate(-45deg);
          -moz-transform: translateY(-.75rem) rotate(-45deg);
          -ms-transform: translateY(-.75rem) rotate(-45deg);
          -o-transform: translateY(-.75rem) rotate(-45deg);
          transform: translateY(-.75rem) rotate(-45deg);
        }
      }
    }
  }
}
/* Transitions */
.list-enter-from {
  opacity: 0;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
}

.list-enter-to {
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}

.list-enter-active {
  -webkit-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  -moz-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  transition: opacity .4s ease-in-out, transform .6s ease-in-out;
}

.list-leave-to {
  opacity: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
}

.list-leave-active {
  -webkit-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  -moz-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  -o-transition: opacity .4s ease-in-out, transform .6s ease-in-out;
  transition: opacity .4s ease-in-out, transform .6s ease-in-out;
}
</style>
