<template>
  <div class="c-footer">
    <div class="container flex justify-center">
      <div class="w-33 flex direction-column align-items-center@sm-max">
        <h3 class="">Strony</h3>
        <router-link to="/polityka-prywatnosci" class="link c-footer__link">
          <img :src="require(`@/assets/page.svg`)">
          <span>Polityka prywatności</span>
        </router-link>
        <a class="link c-footer__link" href="https://fb.com/adamkanigowskipl" target="_blank">
          <img :src="require(`@/assets/facebook.svg`)">
          <span>Facebook</span>
        </a>
        <a class="link c-footer__link" href="https://www.linkedin.com/in/adam-kanigowski-446aba215/" target="_blank">
          <img :src="require(`@/assets/linkedin.svg`)">
          <span>Linkedin</span>
        </a>
        <a class="link c-footer__link" href="https://github.com/adzix8" target="_blank">
          <img :src="require(`@/assets/github.svg`)">
          <span>Github</span>
        </a>
      </div>
      <div class="w-33 flex direction-column align-items-center@sm-max">
        <h3>Kontakt</h3>
        <a class="link c-footer__link" href="tel:+48787951751">
          <img :src="require(`@/assets/phone-icon.svg`)">
          <span class="number">+48 787 951 751</span>
        </a>
        <a class="link c-footer__link" href="mailto:kontakt@adamkanigowski.pl">
          <img :src="require(`@/assets/envelope.svg`)">
          <span>kontakt@adamkanigowski.pl</span>
        </a>
      </div>
      <div class="w-33 flex text--right justify-content-center@sm-max">
        <img :src="require(`@/assets/logo_invert.svg`)"
             alt="Logo AK"
             class="c-footer__logo mr-2">
        <div class="flex direction-column justify-end">
          <p class="m-0">Wszystkie prawa zastrzeżone.</p>
          <p class="m-0">© <span class="number">{{ year }}</span> adamkanigowski.pl</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  setup() {
    const year = new Date().getFullYear();

    return { year };
  },
};
</script>

<style lang="scss" scoped>
@import '../scss/vars';

.c-footer {
  color: $text-invert-color;
  background-color: $secondary-color;
  padding: $padding-3+$padding-2 0 $padding-3 0;

  &__logo {
    width: auto;
    height: 5rem;
    align-self: flex-end;

    @media #{$sm-max} {
      margin-top: $margin-2;
    }
  }

  &__link {
    color: $text-invert-color;
    margin-bottom: $margin-1;
    display: flex;
    align-items: center;
    line-height: 1;

    img {
      margin-right: $margin-1;
    }

    &:hover, &:focus {
      color: $primary-color;
    }
  }
}

@media #{$sm-max} {
  .align-items-center\@sm-max {
    align-items: center;
  }

  .justify-content-center\@sm-max {
    justify-content: center;
  }
}
</style>
